// ** Initial State
const initialState = {
  allData: []
}

const vehicles = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ACTION_DATA':
      return { ...state, allData: action.data }
    default:
      return { ...state }
  }
}
export default vehicles
