// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import garages from '@src/views/pages/garages/store/reducer'
import drivers from '@src/views/pages/drivers/store/reducer'
import vehicles from '@src/views/pages/vehicles/store/reducer'
import calendar from '@src/views/pages/calendar/store/reducer'
import inspections from '@src/views/pages/inspections/store/reducer'
import chat from '@src/views/pages/chat/store/reducer'
import dashboard from '@src/views/pages/dashboard/store/reducer'
import actions from '@src/views/pages/timeline/store/reducer'
import invoices from '@src/views/pages/invoices/store/reducer'
import parts from '@src/views/pages/parts/store/reducer'

const rootReducer = combineReducers({
  auth,
  garages,
  drivers,
  navbar,
  layout,
  vehicles,
  calendar,
  chat,
  inspections,
  dashboard,
  actions,
  invoices,
  parts
})

export default rootReducer
