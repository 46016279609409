const initialState = {
    garageList: [],
    invoiceData: [],
    invoiceDetail: null,
    isLoading: false,
    vehicleList: [],
    totalPages: 0,
    totalRecords: 0,
    err:null
  }
  
  const invoices = (state = initialState, action) => {
    switch (action.type) {
      case "GET_GARAGE_LIST":
        const garages = action.payload
        return {
          ...state,
          garageList: garages.map((item) => {
            return {
              label: item.details.garage_name,
              value: item._id
            }
          })
        }
      case "GET_INVOICE_DATA_SUCCESS":
        const { data, totalPages } = action.payload
        return {
          ...state,
          invoiceData: data,
          totalRecords: data.length,
          totalPages,
          isLoading: false
        }
      case "GET_INVOICE_DATA_FAILED":
        return {
          ...state,
          invoiceData: [],
          totalRecords: 0,
          totalPages: 0,
          isLoading: false
        }
      case "GET_INVOICE_DETAIL_SUCCESS":
        return {
            ...state,
            invoiceDetail: action.payload,
            isLoading: false
        }
      case "SET_PARTS_LOADING":
        return {
          ...state,
          isLoading: true
        }
      default:
        return state
    }
  }
  
  export default invoices
  