const initialState = {
  inspectionData: [],
  params: null,
  allData: [],
  totalPages: 0,
  totalRecords: 0,
  filteredData: [],
  currentData: {
    _id: null,
    vehicle: null,
    driver: null,
    reportType: null,
    pictures: [],
    mileage: null,
    vehicleControl1: null,
    vehicleControl2: null,
    bodyCheck: null,
    fuelLevel: 0,
    maintenance: null,
    signatures: null,
    documents: null,
    action: null,
    selectedVehicle: null,
    canvasFile: null,
    waitingDuration: 0
  },
  waitStartTime: null,
  serviceStartTime: null,
  isLoading: true
}

const inspections = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA":
      return {
        ...state,
        inspectionData: action.data,
        totalPages: action.totalPages,
        params: action.params,
        isLoading: false
      }
    case "GET_ALL_DATA":
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length,
        isLoading: false
      }
    case "FILTER_DATA":
      const value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            const startsWithCondition = item.vehicle && (
              item.date.toLowerCase().startsWith(value.toLowerCase()) ||
              item.vehicle.make.name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.vehicle.model.name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.vehicle.vin_number.toLowerCase().startsWith(value.toLowerCase()) ||
              item.vehicle.car_plate.toLowerCase().startsWith(value.toLowerCase()))
// 
            const includesCondition = item.vehicle && (
              item.date.toLowerCase().includes(value.toLowerCase()) ||
              item.vehicle.make.name.toLowerCase().includes(value.toLowerCase()) ||
              item.vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
              item.vehicle.vin_number.toLowerCase().startsWith(value.toLowerCase()) ||
              item.vehicle.car_plate.toLowerCase().includes(value.toLowerCase()))

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
          .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.allData
        return { ...state, filteredData }
      }
    case "UPDATE_CURRENT_DATA":
      const data = action.data
      const newCurrentData = {
        ...state.currentData,
        ...data
      }
      return { ...state, currentData: newCurrentData }
    case "START_TIMING":
      return { ...state, serviceStartTime: new Date() }
    case "START_WAITING_TIMING":
      return { ...state, waitStartTime: new Date() }
    default:
      return state
  }
}

export default inspections
