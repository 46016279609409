// ** Initial State
const initialState = {
  driverAllData: [],
  driverData: [],
  total: 0,
  params: {},
  selectedDriver: null,
  isLoading: false,
  actionLoading: false,
  selectedDriverActions: []
}

const drivers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DRIVER_ALL_DATA_SUCCESS':
      return { ...state, driverAllData: action.payload }
    case 'GET_DRIVER_ALL_DATA_FAILED':
      return { ...state, driverAllData: [] }
    case 'GET_DRIVER_DATA':
      return { ...state, params: action.payload, isLoading: true }
    case 'GET_DRIVER_DATA_SUCCESS':
      return { ...state, driverData: action.payload.drivers, total: action.payload.total, isLoading: false }
    case 'GET_DRIVER_DATA_FAILED':
      return { ...state, driverData: [], total: 0, isLoading: false }
    case 'GET_DRIVER':
      return { ...state, isLoading: true }
    case 'GET_DRIVER_SUCCESS':
      return { ...state, selectedDriver: action.payload, isLoading: false }
    case 'GET_DRIVER_FAILED':
      return { ...state, selectedDriver: null, isLoading: false }
    case 'GET_DRIVER_ACTIONS':
      return { ...state, actionLoading: true }
    case 'GET_DRIVER_ACTIONS_SUCCESS':
      return { ...state, selectedDriverActions: action.payload, actionLoading: false }
    case 'GET_DRIVER_ACTIONS_FAILED':
      return { ...state, selectedDriverActions: [], actionLoading: false }
    case 'UPDATE_DRIVER':
      return { ...state, selectedDriver: null }
    case 'DELETE_DRIVER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default drivers
