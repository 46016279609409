const initialState = {
    partsData: [],
    partsDetail: null,
    isLoading: false,
    vehicleList: [],
    totalPages: 0,
    totalRecords: 0,
    err:null
  }
  
  const parts = (state = initialState, action) => {
    switch (action.type) {
      case "GET_VEHICLE_LIST":
        return {
          ...state,
          vehicleList: action.payload.map((item) => {
            return {
              label: item.car_plate,
              value: item._id,
              drivers: item.car_driver
            }
          })
        }
      case "GET_PARTS_DATA_SUCCESS":
        const { data, totalPages } = action.payload
        return {
          ...state,
          partsData: data,
          totalRecords: data.length,
          totalPages,
          isLoading: false
        }
      case "GET_PARTS_DATA_FAILED":
        return {
          ...state,
          partsData: [],
          totalRecords: 0,
          totalPages: 0,
          isLoading: false
        }
      case "GET_PARTS_DETAIL_SUCCESS":
        return {
          ...state,
          partsDetail: action.payload
        }
      case "SET_PARTS_LOADING":
        return {
          ...state,
          isLoading: true
        }
      default:
        return state
    }
  }
  
  export default parts
  