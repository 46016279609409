// ** Initial State
const initialState = {
  vehicleAllData: [],
  vehicleData: [],
  total: 1,
  params: {
    dirver: [],
    garage: [],
    make: [],
    model: [],
    year: [1990, 2021],
    mileage: [0, 200000],
    status: []
  },
  details: {
    pictures: [],
    vin_number: "",
    make: "",
    model: "",
    year: "",
    car_plate: "",
    car_driver: [],
    mileage: "",
    trim: "",
    version: "",
    transmission: "",
    fuel_type: "",
    vin_check: false
  },
  selectedVehicle: null,
  selectedVehicleActions: [],
  viewLoading: false,
  tableLoading: false,
  actionLoading: false
}

const vehicles = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VEHICLE_ALL_DATA_SUCCESS':
      return { ...state, vehicleAllData: action.payload }
    case 'GET_VEHICLE_ALL_DATA_FAILED':
      return { ...state, vehicleAllData: [] }
    case 'GET_VEHICLE_DATA':
      return { ...state, tableLoading: true, params: action.payload }
    case 'GET_VEHICLE_DATA_SUCCESS':
      return { ...state, vehicleData: action.payload.vehicles, total: action.payload.total, tableLoading: false }
    case 'GET_VEHICLE_DATA_FAILED':
      return { ...state, vehicleData: [], total: 0, tableLoading: false }
    case 'GET_VEHICLE':
      return { ...state, viewLoading: true }
    case 'GET_VEHICLE_SUCCESS':
      return { ...state, viewLoading: false, selectedVehicle: action.payload, details: { ...state.details, ...action.payload } }
    case 'GET_VEHICLE_FAILED':
      return { ...state, viewLoading: false }
    case 'UPDATE_TABLE_LOADING':
      return { ...state, tableLoading: action.loading }
    case 'DELETE_VEHICLE':
      return { ...state }
    case 'UPDATE_VEHICLE_DETAILS':
      return { ...state, details: { ...state.details, ...action.details } }
    case 'RESET_VEHICLE_DETAILS':
      return { ...state, selectedVehicle: null, details: initialState.details }
    case 'GET_VEHICLE_ACTIONS':
      return { ...state, actionLoading: true }
    case 'GET_VEHICLE_ACTIONS_SUCCESS':
      return { ...state, selectedVehicleActions: action.payload, actionLoading: false }
    case 'GET_VEHICLE_ACTIONS_FAILED':
      return { ...state, selectedVehicleActions: [], actionLoading: false }
    default:
      return { ...state }
  }
}
export default vehicles
