import React, { createContext } from 'react'
import io  from 'socket.io-client'
import { useDispatch } from 'react-redux'
import { setOnlineUsers, receiveNewMessage } from './views/pages/chat/store/actions'
// , Initialize, AddTypingUser, RemoveTypingUser, ReceiveNewMessage
const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {
  let socket
  let ws

  const dispatch = useDispatch()

  const sendMessage = (sender, receiver, message) => {
    const payload = { sender, receiver, message }
    socket.emit("send-message", JSON.stringify(payload))
  }

  if (!socket) {
    // Get user login id
    const login = localStorage.getItem("userData")
    if (login) { // If logged in, connect socket server
      const user = JSON.parse(login)

      // Connect Socket Server
      // socket = io(`http://127.0.0.1:5000?userId=${user._id}`, {
      //   transports: ["websocket"]
      // })
      
      socket = io(`http://goautofast.com?userId=${user._id}`, {
        transports: ["websocket"]
      })

      // Get online users
      socket.on("init", (onlineUsers) => {
        dispatch(setOnlineUsers(onlineUsers))
      })

      // Event if user disconnected
      socket.on("user_disconnected", (onlineUsers) => {
        dispatch(setOnlineUsers(onlineUsers))
      })

      // Event for send message success
      socket.on("message-sent-success", (data) => {
        console.log(data)
      })

      // Event for send message failed
      socket.on("message-sent-failed", (data) => {
        console.log(data)
      })

      // Event for receive message
      socket.on("receive-message", (data) => {
        dispatch(receiveNewMessage(data))
      })

    //   dispatch(Initialize())
    }
    ws = {
      socket,
      sendMessage
    }
  }

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  )
}