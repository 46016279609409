// ** Initial State
const initialState = {
  garageAllData: [],
  garageData: [],
  total: 0,
  params: {},
  selectedGarage: null,
  isLoading: false,
  actionLoading: false,
  selectedGarageActions: []
}

const garages = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: true }
    case 'GET_GARAGE_ALL_DATA':
      return { ...state, garageAllData: action.data }
    case 'GET_GARAGE_DATA':
      return { ...state, isLoading: true, params: action.payload }
    case 'GET_GARAGE_DATA_SUCCESS':
      return { ...state, isLoading: false, garageData: action.payload.garages, total: action.payload.total }
    case 'GET_GARAGE_DATA_FAILED':
      return { ...state, isLoading: false, garageData: [], total: 0 }
    case 'GET_GARAGE':
      return { ...state, isLoading: true }
    case 'GET_GARAGE_SUCCESS':
      return { ...state, selectedGarage: action.payload, isLoading: false }
    case 'GET_GARAGE_FAILED':
      return { ...state, selectedGarage: null, isLoading: false }
    case 'GET_GARAGE_ACTIONS':
      return { ...state, actionLoading: true }
    case 'GET_GARAGE_ACTIONS_SUCCESS':
      return { ...state, selectedGarageActions: action.payload, actionLoading: false }
    case 'GET_GARAGE_ACTIONS_FAILED':
      return { ...state, selectedGarageActions: [], actionLoading: false }
    case 'UPDATE_GARAGE':
      return { ...state, selectedGarage: null }
    case 'DELETE_GARAGE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default garages
