// ** Initial State
const initialState = {
    statsData: null,
    mileageData: null,
    inspectionData: null,
    inspectionStateData: null,
    carModelStateData: null,
    yearMileageChartData: null,
    minMileage: 0,
    maxMileage: 20000,
    yearVehicleChartData: null,
    transmissionChartData: null,
    fuelTypeChartData: null,
    actionTimelineData: null,
    linkedVehicles: [],
    linkedGarages: [],
    alerts: [],
    events: []
  }
  
const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STATS_DATA':
      return { ...state, statsData: action.data }
    case 'GET_DRIVER_STATS_DATA':
      return { ...state, statsData: action.data }
    case 'GET_MILEAGE_CHART_DATA':
      return { ...state, mileageData: action.data }
    case 'GET_INSPECTION_CHART_DATA':
      return { ...state, inspectionData: action.data }
    case 'GET_INSPECTION_STATE_DATA':
      return { ...state, inspectionStateData: action.data }
    case 'GET_CAR_MODEL_STATE_DATA':
      return { ...state, carModelStateData: action.data }
    case 'GET_YEAR_MILEAGE_CHART_DATA':
      return { ...state, yearMileageChartData: action.data.result, minMileage: action.data.minMileage, maxMileage: action.data.maxMileage }
    case 'GET_YEAR_VEHICLE_CHART_DATA':
      return { ...state, yearVehicleChartData: action.data }
    case 'GET_TRANSMISSION_CHART_DATA':
      return { ...state, transmissionChartData: action.data }
    case 'GET_FUEL_TYPE_CHART_DATA':
      return { ...state, fuelTypeChartData: action.data }
    case 'GET_ACTION_TIMELINE_DATA':
      return { ...state, actionTimelineData: action.data }
    case 'GET_LINKED_VEHICLES':
      return { ...state, linkedVehicles: action.data }
    case 'GET_LINKED_GARAGES':
      return { ...state, linkedGarages: action.data }
    case 'GET_ALERTS':
      return { ...state, alerts: action.data }
    case 'GET_EVENTS':
      return { ...state, events: action.data }
    default:
      return { ...state }
  }
}
export default dashboard
